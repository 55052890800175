<template>
    <section>
  <match-progress></match-progress>

    <b-card>
      <b-row
        align-h="between"
        align-v="center"
      >
        <b-col sm="6">
          <h2 class="mb-1 mb-sm-0">
            Locked Matches
          </h2>
        </b-col>
        <b-col sm="6">
          <div class="d-flex align-items-end justify-content-end">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <b-button
                  variant="outline-primary"
                  class="ml-2"
                  :disabled="!rows.length"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadMatch('csv')">
                <span class="align-middle ml-50">Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadMatch('xlsx')">
                <span class="align-middle ml-50">Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <b-button
                  variant="outline-primary"
                  class="ml-2"
                  :disabled="!rows.length"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Formatted Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadMatch('csv', 'formatted')">
                <span class="align-middle ml-50">Formatted Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadMatch('xlsx', 'formatted')">
                <span class="align-middle ml-50">Formatted Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <vue-good-table
        v-if="!showMatchAlert"
        class="mt-3"
        mode="remote"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :rtl="isRtl"
        :search-options="{
          enabled: false,
        }"
        :select-options="{ 
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'bulk-selection-wrapper',
          selectionText: 'Matches selected',
          clearSelectionText: 'Clear Selections',
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="bulkSelectionChange"
      >
        <template slot="emptystate">
          <div class="text-center">
            {{ emptyStateText }}
          </div>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <!-- <span v-if="props.column.field === 'status'">
            <b-badge :variant="STATUS_COLOR[props.row.status]">
              {{ props.row.status }}
            </b-badge>
          </span> -->
          <!-- Column: Action -->
          <span v-if="props.column.field === 'compat'">
            <b-link
              href="#"
              class="compare-user d-flex align-items-center"
              @click="openCompareModal(props.row)"
            >
        
              <b-progress
                class=""
                :value="props.row.compatibility"
                variant="primary"
                animated
              />{{props.row.compatibility}}%

            </b-link>
          </span>
          <span v-if="props.column.field === 'notify_mentor'">
            <b-button v-b-tooltip.hover.righttop="'Notify ' + roleAlias(userRoles.MENTOR, defaultProgram)" v-if="canSendNotify(props.row)" variant="light" @click="openNotifyModal(props.row)"><feather-icon icon="MailIcon" size="20" /></b-button>
            <span v-else v-b-tooltip.hover.righttop="roleAlias(userRoles.MENTOR, defaultProgram) + ' notified'"><b-button variant="light" disabled="true" ><aom-icon icon="MailOpen" size="20" /></b-button></span>
          </span>
          <div
            v-if="props.column.field === 'action'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap vgt-center-align"
          >
            <div class="align-items-center mb-0 mt-0">
              <b-button
                size="sm"
                variant="outline-none"
                class="nav action-menu pr-0"
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                >
                  <template 
                    #button-content
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />                
                  </template>

                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                    @click="unLockMatch(props.row.id, props.row)"
                  >
                    <feather-icon
                      size="16"
                      icon="UnlockIcon"
                      class="mr-50"
                    />
                    <span>Unlock Match</span>
                  </action-dropdown-item>

                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{
                      name: 'champion-program-communications',
                      query: {
                        user_ids: props.row.match_users.map((u) => u.user_id),
                      },
                    }"
                  >
                    <feather-icon
                      size="16"
                      icon="MessageSquareIcon"
                      class="mr-50"
                    />
                    <span>Message Matched Participants</span>
                  </action-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{ name: 'champion-match-view', params: { id: defaultProgramId, matchId: props.row.id } }"
                  >
                    <feather-icon
                      size="16"
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View Matched Participants</span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
              <action-button
                variant="outline-none"
                class="btn-icon"
                @click="publishMatch(props.row.id, props.row)"
              >
                <feather-icon
                  :icon="isScheduledMatch(props.row) ? 'ClockIcon' : 'UploadCloudIcon'"
                  size="20"
                />
              </action-button>
            </div>
          </div>
          <div
            v-if="props.column.field === 'user.0.full_name'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.match_users[0].user_id },
                }"
                class="table-primary-link"
              >
                {{ menteeRoleInMatch(props.row) }}
              </router-link>
            </div>
          </div>
          <div
            v-if="props.column.field === 'user.1.full_name'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.match_users[1].user_id },
                }"
                class="table-primary-link"
              >
                {{ mentorRoleInMatch(props.row) }}
              </router-link>
            </div>
          </div>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <table-pagination
            :per-page="perPage"
            :total="total"
            @perPageChanged="
              (value) => props.perPageChanged({ currentPerPage: value })
            "
            @pageChanged="
              (value) => props.pageChanged({ currentPage: value })
            "
          />
        </template>
        <div slot="selected-row-actions">
          <matches-bulk-actions
            :matches="bulkSelection"
            :can-un-lock="true"
            :can-publish="true"
            :can-notify-mentor="canNotifyMentors"
            @updated="loadItems"
          />
        </div>
      </vue-good-table>
      <match-alert
        v-else
        variant="success"
        show
        title="You have no locked matches to review"
        message="Select a matching pool and choose matches to be locked"
        >
        </match-alert>
      <b-modal
        id="updating-modal"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="d-block text-center">
          <b-spinner
            medium
            variant="primary"
            class="mt-2"
          />
          <h5 class="mt-2">
            Updating...
          </h5>
        </div>
      </b-modal>
    </b-card>
    <div class="d-flex justify-content-between
    ">
    <router-link
        :to="{ name: 'champion-matches-matching-pools'}"
        class="btn btn-primary btn-icon  mb-4"
    >
      <feather-icon
        icon="ArrowLeftIcon"" 
      />
      Previous 
    </router-link>
    <router-link
        :to="{ name: 'champion-matches-live-matches'}"
        class="btn btn-primary btn-icon  mb-4"
    >
    Next
    <feather-icon
      icon="ArrowRightIcon"" 
    />
  </router-link>
            </div>
    <user-application-summary-modal
      ref="userAppSummary"
      :match-users="matchUsers"
      :compare-percentage="comparePercentage"
      @clear="clearModal"
    />
    <publish-match-modal 
      ref="publishMatchModal"
      :matches="matchesToPublish"
      @updated="onPublish"
    />
    <notify-mentor-modal 
      ref="notifyMentorModal"
      :matches="matchesToNotify"
      @mentorNotified="mentorNotified"
    />
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BModal,
  BSpinner,
  BLink,
  BDropdown,
  BDropdownItem,
  BNavItemDropdown,
  BProgress
} from "bootstrap-vue";
import { GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, PRIMARY_DATE_FORMAT } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRoles, matchStatus, matchStatusDisplay } from '@models';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import matchesService  from "@/services/matchesService";
import { mapGetters } from 'vuex';
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import UserApplicationSummaryModal from './UserApplicationSummaryModal';
import MatchesBulkActions from "./actions/MatchesBulkActions.vue";
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import PublishMatchModal from './PublishMatchModal.vue';
import { MAX_INFORM_MENTOR_IN_LOCKED_MATCH, roleAlias } from "@/@aom-core/utils/utils";
import MatchProgress from "@/views/apps/champion/matches/MatchProgress.vue";
import MatchAlert from "@/views/apps/champion/matches/MatchAlert.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import AomIcon from "@aom-core/AomIcon";
import NotifyMentorModal from "./NotifyMentorModal.vue";
import { match } from "assert";

export default {
  name: 'LockedMatches',
  components: {
    NotifyMentorModal,
    AomIcon,
    ActionButton,
    ActionDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    VueGoodTable,
    TablePagination,
    BModal,
    BSpinner,
    UserApplicationSummaryModal,
    MatchesBulkActions,
    BDropdown,
    BDropdownItem,
    BNavItemDropdown,
    PublishMatchModal,
    BProgress,
    MatchProgress,
    MatchAlert
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      bulkSelection: [],
      isLoading: false,
      modalShow: false,
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [],
      options: {
        chart: {
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
      },
      matchUsers: [],
      comparePercentage: 0,
      matchesToPublish: [],
      matchesToNotify: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    },
    showMatchAlert() {
      return !this.isLoading && this.rows.length === 0 && !this.columnFilters.length;
    },
    emptyStateText() {
      return this.columnFilters.length ? "No results found, please try searching again" : "No data for table";
    },
    canNotifyMentors() {
      return this.defaultProgram.inform_mentor_in_locked_match && this.bulkSelection.find(match => match.notify_mentor_count < MAX_INFORM_MENTOR_IN_LOCKED_MATCH) != undefined;
    },
  },
  created() {
    this.columns = [
      {
        label: roleAlias(userRoles.MENTEE, this.defaultProgram),
        field: 'user.0.full_name',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTEE, this.defaultProgram),
        },
        width: "8em",
      },
      {
        label: roleAlias(userRoles.MENTOR, this.defaultProgram),
        field: 'user.1.full_name',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTOR, this.defaultProgram)
        },
        width: "8em",
      },
      {
        label: "Compatibility",
        field: "compat",
        tdClass: "text-center",
        filterOptions: {
          enabled: true,
          placeholder: "Compatibility",
        },
        width: "6em",
        
      },
      {
        label: "Pool Name",
        field: 'pool.title',
        width: "10em",
        filterOptions: {
          enabled: true,
          placeholder: "Pool Name",
        },
      },
    ];

    if (this.defaultProgram.inform_mentor_in_locked_match) {
      this.columns.push({
        label: "Notify " + roleAlias(userRoles.MENTOR, this.defaultProgram),
        field: 'notify_mentor',
        width: "10em",
      });
    }
    this.columns.push({
      field: 'action',
      width: "6em",
      sortable: false
    });
    this.loadItems();
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-started_at"));
    datePickers.push(document.getElementsByName("vgt-ended_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT
      });
    });
  },
  methods: {
    openNotifyModal(match) {
      this.matchesToNotify = [match];
      this.$refs.notifyMentorModal.open();
    },
    canSendNotify(match) {
      return match.notify_mentor_count < MAX_INFORM_MENTOR_IN_LOCKED_MATCH;
    },
    isScheduledMatch(match) {
      return match.status_id === matchStatus.SCHEDULED;
    },
    bulkSelectionChange(args) {
      const { selectedRows } = args;
      if(Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
      }
    },
    clearModal () {
      this.matchUsers = [];
      this.comparePercentage = 0;
    },
    openCompareModal(args) {
      const { match_users, compatibility } = args;
      // eslint-disable-next-line camelcase
      this.matchUsers = match_users;
      this.comparePercentage = compatibility;
      this.$refs.userAppSummary.show();
    },
    formatProgramStatus (value) {
      const {id } = value;
      return matchStatusDisplay[id];
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onPublish() {
      this.matchesToPublish = [];
      this.loadItems();
    },
    mentorNotified() {
      this.matchesToNotify = [];
      this.loadItems();
    },
    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (field === 'compat') {
          field = 'compatibility';
        }
        if (field === 'notify_mentor') {
          field = 'notify_mentor_count';
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "status",
        "started_at",
        "ended_at",
        "user.0.full_name",
        "user.1.full_name",
        "compat",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'compat') {
            columnFilters.push({
              field: 'compatibility',
              value: params.columnFilters[col],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
          
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await matchesService.getListLockedMatches(this.defaultProgramId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      } 
    },
    menteeRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentee = matches.match_users.filter(m => m.role_id === userRoles.MENTEE);
        if(mentee. length > 0) {
          return mentee[0].user.full_name;
        }
      }
    },
    mentorRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentor = matches.match_users.filter(m => m.role_id === userRoles.MENTOR);
        if(mentor.length > 0) {
          return mentor[0].user.full_name;
        }
      }
    },
    unLockMatch (matchId, match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to unlock this match?', {
          title: 'Unlock this Match ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unlock Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
          id: 'unlock-modal'
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatus.NONE };
            try {
              this.$bvModal.show('updating-modal');
              await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              this.$toast(makeSuccessToast('Match Unlocked successfully.'));
              this.loadItems();
            } catch (e) {
              this.$toast(makeErrorToast('Something went wrong. Match not unlocked.'));
              this.$log.error(e);
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });         
    },
    publishMatch (matchId, match) {
      this.matchesToPublish = [match];
      this.$nextTick(() => {
        this.$refs.publishMatchModal.open();
      });
    },
    onDownloadMatch(type, style) {
      let downloadLink = `${BE_API_URL}/programs/${this.$route.params.id}/matches/locked/download/${type}`;
        
      if (style) {
        downloadLink += `/${style}`;
      }
      
      downloadLink +=
        `?search=${encodeURIComponent(
          JSON.stringify({
            page: this.page,
            columnFilters: this.columnFilters,
            sort: this.sort,
          })
        )}`;
      window.document.location = downloadLink;
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page, roleAlias, userRoles
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
.compare-user {
  cursor: pointer; 
}

</style>
<style lang="scss" scoped>
.action-menu {
  display: inline-block;
}
::v-deep .apexcharts-canvas {
    margin: auto;
  }
  .bulk-selection-wrapper {
  background: none;
  font-size: 14px;
  color: inherit;
}
::v-deep .vgt-selection-info-row{
  background: none;
  font-size: 14px;
  color: inherit;
}
.progress{
    flex:1;
    margin-right: 10px;
  }
</style>