<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <b-card v-else>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12 nav-program-tab"
      nav-class="nav-left"
      active-nav-item-class="active-custom"
      no-nav-style
    >
      <!-- General tab -->
      <b-tab v-if="!isProgramTemplate">
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="tabIndex == 0 ? 'active-button' : ''"
          >
            <feather-icon
              icon="GlobeIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              General
            </p>
            <p class="card-text font-small-3 mb-0">
              Settings
            </p>
          </div>
          <span
            v-if="!program.default_champion"
            v-b-tooltip.hover.righttop="'You have yet to assign a Default Champion to the program!'"
            class="validation-icon ml-5"
            variant="primary"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="20"
            />
          </span>
        </template>
        <details-tab
          v-if="program"
          :program="program"
          :disabled="isChampionPortal"
        />
      </b-tab>
      <!--/ Details tab -->

      <!-- Theme tab -->
      <b-tab
        v-if="!isProgramTemplate"
        href="theme"
      >
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="tabIndex === 1 ? 'active-button' : ''"
          >
            <feather-icon
              icon="Edit2Icon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Theme
            </p>
            <p class="card-text font-small-3 mb-0">
              Branding
            </p>
          </div>
        </template>
        <themes-tab
          v-if="program"
          :program="program"
          @back="backToPreviousTab"
        />
      </b-tab>
      <!-- Theme tab -->

      <!-- Champions tab -->
      <b-tab
        v-if="(isAdminPortal || hasProgramAdminRole) && !isProgramTemplate"
        lazy
      >
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="tabIndex === 2 ? 'active-button' : ''"
          >
            <feather-icon
              icon="GlobeIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Champions
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
          <span
            v-if="program.champions_count === 0"
            v-b-tooltip.hover.righttop="'You have yet to assign a Champion to the program!'"
            class="validation-icon ml-5"
            variant="primary"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="20"
            />
          </span>
        </template>
        <champions
          :title="programName"
          :program="program"
          :disabled="isChampionPortal && !hasProgramAdminRole"
          :is-program-admin="hasProgramAdminRole"
          @championAdded="fetchProgram"
        />
      </b-tab>
      <!--/ Champions tab -->

      <!-- Integrations -->
      <b-tab v-if="!isProgramTemplate">
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              tabIndex === 2 || (tabIndex === 3 && isAdminPortal)
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="PhoneIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Integrations
            </p>
            <p class="card-text font-small-3 mb-0">
              Voice calling integrations
            </p>
          </div>
        </template>
        <connections-tab
          v-if="program"
          :program="program"
          :disabled="isChampionPortal && !hasProgramAdminRole"
          @back="backToPreviousTab"
        />
      </b-tab>

      <!-- SSO -->
      <b-tab v-if="!isProgramTemplate && (isAdminPortal || program.program_saml.is_enabled)">
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              tabIndex === 3 || (tabIndex === 4 && isAdminPortal)
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="UserIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Single sign on
            </p>
            <p class="card-text font-small-3 mb-0">
              SSO settings
            </p>
          </div>
        </template>
        <sso-tab
          v-if="program"
          :program="program"
          @back="backToPreviousTab"
        />
      </b-tab>

      <!-- Applications tab -->
      <b-tab
        v-if="isAdminPortal"
        lazy
      >
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              (tabIndex === 5 || (tabIndex === 0 && isProgramTemplate))
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="EditIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Applications
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
          <span
            v-if="!program.application_set"
            v-b-tooltip.hover.righttop="'You have yet to assign an Application to the program!'"
            class="validation-icon ml-5"
            variant="primary"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="20"
            />
          </span>
        </template>
        <applications
          :in-program-detail="true"
          :title="programName"
          :program="program"
          @applicationAdded="fetchProgram"
        />
      </b-tab>

      <!-- Surveys tab -->
      <b-tab
        v-if="isAdminPortal"
        lazy
      >
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              (tabIndex === 6 || (tabIndex === 1 && isProgramTemplate))
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="CheckSquareIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Surveys
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
        </template>
        <surveys
          :in-program-detail="true"
          :title="programName"
          :program="program"
        />
      </b-tab>

      <!-- Surveys tab -->
      <b-tab
        v-if="isAdminPortal"
        lazy
      >
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              (tabIndex === 7 || (tabIndex === 2 && isProgramTemplate))
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="MessageSquareIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Communications
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
        </template>
        <communications
          :is-program-communication="true"
          :title="programName"
          :program="program"
        />
      </b-tab>

      <!-- Resources tab -->
      <b-tab
        v-if="isAdminPortal"
        lazy
      >
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              (tabIndex === 8 || (tabIndex === 3 && isProgramTemplate))
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="SettingsIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Resources
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
        </template>
        <resources
          :is-program-resource="true"
          :title="programName"
          :program="program"
        />
      </b-tab>

      <!-- Training tab -->
      <b-tab
        v-if="isAdminPortal"
        lazy
      >
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              (tabIndex === 9 || (tabIndex === 4 && isProgramTemplate))
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="MonitorIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Training
            </p>
            <p class="card-text font-small-3 mb-0" />
          </div>
        </template>
        <training
          :is-program-training="true"
          :title="programName"
          :program="program"
        />
      </b-tab>

      <!-- Reminders Tab -->
      <b-tab v-if="!isProgramTemplate">
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              tabIndex === 4 || (tabIndex === 10 && isAdminPortal)
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="ClockIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Reminders
            </p>
            <p class="card-text font-small-3 mb-0">
              Automated reminders
            </p>
          </div>
        </template>
        <reminders-tab
          v-if="program"
          :program="program"
        />
      </b-tab>
      <!-- Notifications Tab -->
      <b-tab v-if="!isProgramTemplate">
        <!-- title -->
        <template #title>
          <b-button
            size="sm"
            class="mr-1"
            :class="
              tabIndex === 4 || (tabIndex === 11 && isAdminPortal)
                ? 'active-button'
                : ''
            "
          >
            <feather-icon
              icon="BellIcon"
              size="20"
            />
          </b-button>
          <div class="d-flex flex-column justify-content-center">
            <p class="font-weight-bold mb-0">
              Notifications
            </p>
            <p class="card-text font-small-3 mb-0">
              Program notifications
            </p>
          </div>
        </template>
        <notifications-tab
          v-if="program"
          :program="program"
          @back="backToPreviousTab"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { BTabs, BTab, BCard, VBTooltip, BButton } from "bootstrap-vue";
import DetailsTab from "../programs/general/DetailsTab.vue";
import ConnectionsTab from "../programs/general/ConnectionsTab.vue";
import RemindersTab from "../programs/general/RemindersTab.vue";
import NotificationsTab from "../programs/general/NotificationsTab.vue";
import SsoTab from "../programs/general/SsoTab.vue";
import ThemesTab from "../programs/design/ThemesTab.vue";
import Champions from "@/views/apps/admin/champion/Champions.vue";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import programsService from "@/services/programsService";
import { ProgramClass } from "@models/classes/programClass";
import Applications from "@/views/components/forms/applications/Applications.vue";
import Surveys from "@/views/components/forms/surveys/Surveys.vue";
import Resources from "../resources/Resources.vue";
import Training from "../training/Training.vue";
import Communications from "../communications/Communications.vue";

export default {
  name: "EditProgram",
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    DetailsTab,
    ConnectionsTab,
    SsoTab,
    Champions,
    ThemesTab,
    AomSkeletonLoader,
    Applications,
    Surveys,
    Resources,
    Training,
    Communications,
    RemindersTab,
    NotificationsTab
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      program: new ProgramClass(),
      isLoading: false,
      tabIndex: 0,
      hasProgramChampions: true,
      tabs: [],
    };
  },
  computed: {
    ...mapGetters("app", ["isChampionPortal", "isAdminPortal"]),
    ...mapGetters("profile", ["profile", 'isProgramAdmin', 'isAdmin']),
    programName() {
      return this.program ? this.program.name : '';
    },
    hasProgramAdminRole() {
      return this.isProgramAdmin(this.program?.id);
    },
    isProgramTemplate() {
      return this.program?.is_template === true;
    }
  },
  watch: {
    tabIndex: {
      handler() {
        const tabName = this.tabs.find((tab, i) => i === this.tabIndex);
        if (tabName && this.$route.hash !== tabName) {
          this.$router.push({ hash: `${tabName}` });
        }
      },
    },
    "$route.hash": {
      handler(n) {
        if (n) {
          const tabIndex = this.tabs.findIndex(tab => n === tab);
          this.tabIndex = tabIndex;
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchProgram();
    if (!this.isProgramTemplate) {
      this.tabs = [
        "#general",
        "#theme",
        "#champions",
        "#integrations",
        "#sso",
        "#applications",
        "#surveys",
        "#communications",
        "#resources",
        "#training",
        "#reminders",
        "#notifications",
      ];
      if (!this.isAdminPortal && this.program.program_saml?.is_enabled === false) {
        this.tabs = this.tabs.filter(tab => tab !== "#sso");
      }
    } else {
      this.tabs = [
        "#applications",
        "#surveys",
        "#communications",
        "#resources",
        "#training",
      ];
    }

    const tabIndex = this.tabs.findIndex(tab => this.$route.hash === tab);
    this.tabIndex = tabIndex;
  },
  methods: {
    async fetchProgram() {
      try {
        this.isLoading = true;
        const id = this.$route.params.id;
        const response = await programsService.getProgramByProgramId(id);
        const { data } = response;
        this.program = data;
        this.$store.commit('programs/UPDATE_PROGRAM',data);
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(`Unable to fetch Program ${id}.`));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    backToPreviousTab(tab) {
      this.tabIndex = tab;
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-program-tab {
  .nav-link {
    display: flex;
    padding: 0.786rem 0;
    font-size: 1rem;
    line-height: 1rem;
    border: 1px solid transparent;
    color: #5e5873;
  }
  .active-custom {
    .active-button {
      background-color: var(--aom-color-secondary) !important;
      border-color: var(--aom-color-secondary) !important;
      box-shadow: 0 4px 18px -4px rgba(115, 103, 240, 0.65);
    }
  }
  .nav-item:first-child {
    .nav-link {
      padding-top: 0;
    }
  }
}
</style>
